import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Admin from './pages/Admin.jsx';
import Login from './pages/Login.page.jsx';
import Test from './pages/Test.jsx';
import Main from './pages/Main.jsx';
import Layout from './pages/Layout.jsx';
import logo from './logo.svg';
import AuthenticatedRoute from './shared/components/AuthenticatedRoute';
import AuthenticatedGuard from './shared/utils/AuthenticatedGuard';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Navigate, Link} from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { AuthProvider } from './contexts/AuthContext';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>
              <Link to="/main" style={{ textDecoration: 'none', color: 'white' }}>
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
            Workation System Remote
            </Link>
          </Navbar.Brand>
          {/* <Nav className="me-auto">
            <Link className={'nav-link'} to="/">Home</Link>
            <Link className={'nav-link'} to="/admin">管理</Link>
          </Nav> */}
        </Navbar>
        <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/main" element={
            <AuthenticatedGuard>
              <Main />
            </AuthenticatedGuard>
            } />
          </Route>
        </Routes>
        </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
