import React, { useState, useEffect } from "react";
import "./Main.css";
import { ThemeProvider } from "styled-components";
import {
  useMeetingManager,
  MeetingProvider,
  lightTheme,
  VideoTileGrid,
  useLocalVideo,
  Grid,
  MeetingStatus,
  useMeetingStatus,
  useRemoteVideoTileState,
  RemoteVideo,
  useVideoInputs,
  useRosterState,
  useSelectVideoQuality,
  useSelectAudioInputDevice,
  useSelectAudioOutputDevice,
  useBackgroundBlur,
  MicSelection,
  SpeakerSelection,
  CameraSelection,
  QualitySelection,
  PreviewVideo,
  Roster,
  RosterGroup,
  RosterAttendee,
  BackgroundBlurProvider,
  VideoInputBackgroundBlurControl
} from "amazon-chime-sdk-component-library-react";
import { AuthenticationService } from '../service/auth.service';
import { useAuth } from '../contexts/AuthContext';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

const authenticationService = AuthenticationService.getObject();
const MeetingManager = (props) => {
  const updBlurFlg = async (flg) => {
    await authenticationService.updBlurFlg(props.value.clientid, props.value.meetingid, props.value.typed_cd, flg);
  }
  const updBlurFlgOn = async () => {
    await updBlurFlg('1');
  }
  const updBlurFlgOff = async () => {
    await updBlurFlg('0');
  }
  
  return (
    <Stack gap={2}  className="col-md-5 mx-auto">
      <Form.Label>
        プライベートモード
      </Form.Label>
      <ButtonGroup aria-label="updBlurFlgGroup">
        {props.flg && <Button variant="success">オン</Button>}
        {props.flg && <Button variant="secondary" onClick={updBlurFlgOff}>オフ</Button>}
        {!props.flg && <Button variant="secondary" onClick={updBlurFlgOn}>オン</Button>}
        {!props.flg && <Button variant="success">オフ</Button>}
      </ButtonGroup>
      {/* {props.value.typed_cd=='S' && (<button onClick={updBlurFlg}>
        Enable Video
      </button>)} */}
    </Stack>
  );
}
const Main = () => {
  const { currentUser } = useAuth();
  const [isBlur, setisBlur] = useState(false);
  const getBlurFLg = async () => {
    var data = [];
    await authenticationService.getBlurFlg(currentUser.clientid, currentUser.meetingid, currentUser.typed_cd)
      .then((responseJson) => {
        console.log(responseJson);
        data = responseJson['data'];
      })
      .catch((error) =>{
        console.error(error);
      });
      setisBlur(data.blur_flg=='1'?true:false);
      console.log(isBlur);
  };
  useEffect(() => {
    // セントラルは定期的に実行
    if (currentUser.typed_cd == 'S') {
      setInterval(getBlurFLg, 1000 * 10);
    }
  },[]);

  return (
    <Container fluid>
      <ThemeProvider theme={lightTheme}>
        <MeetingManager value={currentUser} flg={isBlur}/>
      </ThemeProvider>
    </Container>
  );
};


export default Main;